import React from "react";
import { Global } from "@emotion/react";
import { ApolloProvider } from "src/apollo";
import { globalStyles } from "client-lib/styles";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { LoginPage, AuthProvider, AuthRoute } from "src/features/auth";
import { DashboardPage } from "src/features/dashboard";
import { CategoryRouter } from "src/features/category";
import { ImageRouter } from "src/features/image";
import { TemplateRouter } from "src/features/template";
import { TextureRouter } from "src/features/texture";
import { MaterialRouter } from "src/features/material";
import { SupportRouter } from "src/features/support";
import { TechProcessRouter } from "src/features/tech-process";
import { UserRouter } from "src/features/user";

export function App() {
  return (
    <BrowserRouter>
      <ApolloProvider>
        <Global styles={globalStyles} />
        <AuthProvider>
          <Switch>
            <Route path={"/login"} exact component={LoginPage} />
            <AuthRoute path={"/"} exact component={DashboardPage} />
            <AuthRoute path={"/categories"} component={CategoryRouter} />
            <AuthRoute path={"/images"} component={ImageRouter} />
            <AuthRoute path={"/templates"} component={TemplateRouter} />
            <AuthRoute path={"/textures"} component={TextureRouter} />
            <AuthRoute path={"/materials"} component={MaterialRouter} />
            <AuthRoute path={"/support"} component={SupportRouter} />
            <AuthRoute path={"/tech-processes"} component={TechProcessRouter} />
            <AuthRoute path={"/users"} component={UserRouter} />
          </Switch>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
