import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import {
  ITEM_HEIGHT,
  grey10,
  grey20,
  grey30,
  gutters,
  primary,
  borderGrey,
} from "client-lib/styles";
import { css } from "@emotion/react";

export const List = styled.div`
  position: relative;
  border: 1px solid ${borderGrey};
`;

export const BaseRow = styled.div<{
  columns?: number;
  columnTemplate?: string;
}>(({ columns = 1, columnTemplate }) => [
  css`
    position: relative;
    display: grid;
    padding-left: ${gutters.lg}px;
    grid-template-columns: ${columnTemplate ??
    new Array(columns).fill("1fr").join(" ")};
    height: ${ITEM_HEIGHT}px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-bottom-color: ${grey20};
  `,
]);

export const HeaderRow = styled(BaseRow)`
  background-color: ${grey30};
`;

export const ListRow = styled(BaseRow)`
  &:hover {
    background-color: ${grey10};
  }
  &:hover:before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 3px;
    background-color: ${primary};
  }
`;

const cellStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const ListCell = styled.div(cellStyles);
export const ListLinkCell = styled(Link)(cellStyles);

export const HeaderCell = styled.div([
  cellStyles,
  css`
    font-weight: bold;
    background-color: ${grey30};
  `,
]);
