import React, { useCallback } from "react";
import { omit } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { MaterialInput, Material } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib/ui";
import { MaterialForm } from "./material-form";
import {
  UpdateMaterial,
  UpdateMaterialVars,
  UpdateMaterialData,
} from "../queries";
import {
  AdminTextures,
  AdminTexturesData,
  AdminTexturesVars,
} from "src/features/texture/queries";

interface Props {
  material: Material;
  onCloseRequest: () => any;
}

export function UpdateMaterialModal({ material, onCloseRequest }: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateMaterialData,
    UpdateMaterialVars
  >(UpdateMaterial);
  const { data } = useQuery<AdminTexturesData, AdminTexturesVars>(
    AdminTextures
  );
  const handleSubmit = useCallback(
    async (input: MaterialInput) => {
      debugger;
      await update({
        variables: {
          materialId: material.id,
          patch: omit(
            input,
            "id",
            "createdAt",
            "updatedAt",
            "deletedAt",
            "__typename"
          ),
        },
      });
      onCloseRequest();
    },
    [update, onCloseRequest]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title={"Редактировать материал"}>
      {error ? <ParseError error={error} /> : null}
      <MaterialForm
        initialValues={material}
        textures={data?.AdminTextures ?? []}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Сохранить"}
      />
    </Modal>
  );
}
