import gql from "graphql-tag";
import {
  Template,
  TemplateInput,
  TemplatePatch,
  TemplateAsset,
  TemplateAssetInput,
  TemplateAssetPatch,
  TemplateEnvironment,
} from "shared";
import {
  TEMPLATE_FULL_FRAGMENT,
  TEMPLATE_ENVIRONMENT_FULL_FRAGMENT,
  TEMPLATE_ASSET_FULL_FRAGMENT,
} from "client-lib/fragments";

export const Templates = gql`
  query Templates {
    Templates {
      ...TemplateFullFragment
    }
  }
  ${TEMPLATE_FULL_FRAGMENT}
`;

export interface TemplatesVars {}

export type TemplatesData = {
  Templates: Template[];
};

export const TemplateEnvironmentQuery = gql`
  query TemplateEnvironment($templateId: String!) {
    TemplateEnvironment(templateId: $templateId) {
      ...TemplateEnvironmentFullFragment
    }
  }
  ${TEMPLATE_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface TemplateEnvironmentVars {
  templateId: string;
}

export type TemplateEnvironmentData = {
  TemplateEnvironment: TemplateEnvironment;
};

export const CreateTemplate = gql`
  mutation CreateTemplate($input: TemplateInput!) {
    CreateTemplate(input: $input) {
      ...TemplateFullFragment
    }
  }
  ${TEMPLATE_FULL_FRAGMENT}
`;

export interface CreateTemplateVars {
  input: TemplateInput;
}

export type CreateTemplateData = {
  CreateTemplate: Template;
};

export const UpdateTemplate = gql`
  mutation UpdateTemplate($templateId: String!, $patch: TemplatePatch!) {
    UpdateTemplate(templateId: $templateId, patch: $patch) {
      ...TemplateFullFragment
    }
  }
  ${TEMPLATE_FULL_FRAGMENT}
`;

export interface UpdateTemplateVars {
  templateId: string;
  patch: TemplatePatch;
}

export type UpdateTemplateData = {
  UpdateTemplate: Template;
};

export const CreateTemplateAsset = gql`
  mutation CreateTemplateAsset($input: TemplateAssetInput!) {
    CreateTemplateAsset(input: $input) {
      ...TemplateAssetFullFragment
    }
  }
  ${TEMPLATE_ASSET_FULL_FRAGMENT}
`;

export interface CreateTemplateAssetVars {
  input: TemplateAssetInput;
}

export type CreateTemplateAssetData = {
  CreateTemplateAsset: TemplateAsset;
};

export const UpdateTemplateAsset = gql`
  mutation UpdateTemplateAsset(
    $templateAssetId: String!
    $patch: TemplateAssetPatch!
  ) {
    UpdateTemplateAsset(templateAssetId: $templateAssetId, patch: $patch) {
      ...TemplateAssetFullFragment
    }
  }
  ${TEMPLATE_ASSET_FULL_FRAGMENT}
`;

export interface UpdateTemplateAssetVars {
  templateAssetId: string;
  patch: TemplateAssetPatch;
}

export type UpdateTemplateAssetData = {
  UpdateTemplateAsset: TemplateAsset;
};
