import React from "react";
import styled from "@emotion/styled";
import { TemplateCode, TemplateInput } from "shared";
import { Button, TextField, SelectField } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { templateInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: TemplateInput;
  onSubmit: (values: TemplateInput) => any;
  disabled?: boolean;
  submitTitle?: string;
  isEditing?: string;
}

export function TemplateForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
  isEditing,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={templateInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<TemplateInput>) => (
        <Form>
          {!isEditing && (
            <SelectField name={"code"} label={"Тип"}>
              {Object.keys(TemplateCode).map((code) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </SelectField>
          )}
          <TextField
            label={"Название"}
            controlProps={{ size: 50 }}
            disabled={disabled}
            name={"title"}
          />
          <TextField
            label={"Картинка"}
            controlProps={{ size: 50 }}
            disabled={disabled}
            name={"image"}
          />
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Сохранить"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
