import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Spinner } from "client-lib/ui";
import { Layout } from "src/features/layout";
import { AdminTextures, AdminTexturesData, AdminTexturesVars } from "./queries";
import { NewTexture } from "./texture-form";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
} from "src/lib";
import { Texture } from "shared";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Текстуры</h1>
      {children}
    </Layout>
  );
}

const columnTemplate = "1fr 0.5fr 1fr 0.3fr 0.3fr";

export function TexturesPage() {
  const { data, loading, error, refetch } = useQuery<
    AdminTexturesData,
    AdminTexturesVars
  >(AdminTextures);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Название</HeaderCell>
          <HeaderCell>Путь к файлу</HeaderCell>
          <HeaderCell>Ширина</HeaderCell>
          <HeaderCell>Высота</HeaderCell>
        </HeaderRow>
        {data.AdminTextures.map((texture: Texture) => {
          const url = `/textures/${texture.id}`;
          return (
            <ListRow key={texture.id} columnTemplate={columnTemplate}>
              <ListLinkCell to={url}>{texture.id}</ListLinkCell>
              <ListLinkCell to={url}>{texture.title}</ListLinkCell>
              <ListLinkCell to={url}>{texture.path}</ListLinkCell>
              <ListLinkCell to={url}>{texture.mmWidth}</ListLinkCell>
              <ListLinkCell to={url}>{texture.mmHeight}</ListLinkCell>
            </ListRow>
          );
        })}
      </List>
      <h2>Создать новую текстуру</h2>
      <NewTexture onCompleted={refetch} />
    </Wrapper>
  );
}
