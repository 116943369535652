import gql from "graphql-tag";
import { pricingCostSchema, TechProcessCode, PricingCost } from "shared";
import { getFieldsFromSchema } from "client-lib/services";

export const PRICING_COST_FULL_FRAGMENT = gql`
  fragment PricingCostFullFragment on PricingCost {
    ${getFieldsFromSchema(pricingCostSchema)}
  }
`;

export const TechProcessPricing = gql`
  query TechProcessPricing($techProcessCode: TechProcessCode!) {
    TechProcessPricing(techProcessCode: $techProcessCode) {
      ...PricingCostFullFragment
    }
  }
  ${PRICING_COST_FULL_FRAGMENT}
`;

export interface TechProcessPricingVars {
  techProcessCode: TechProcessCode;
}

export type TechProcessPricingData = {
  TechProcessPricing: PricingCost[];
};
