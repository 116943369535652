import React from "react";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";
import { SupportMessagesPage } from "./support-messages-page";
import { SupportMessagePage } from "./support-message-page";

export function SupportRouter() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/messages`} />
      <Route exact path={`${path}/messages`} component={SupportMessagesPage} />
      <Route
        exact
        path={`${path}/messages/:supportMessageId`}
        component={SupportMessagePage}
      />
    </Switch>
  );
}
