import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { useRouteMatch } from "react-router";
import { Template, TemplateAssetExpanded } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
} from "client-lib/ui";
import {
  List,
  HeaderRow,
  HeaderCell,
  BaseRow,
  ListLinkCell,
  ParseError,
} from "src/lib";
import { Layout } from "src/features/layout";
import {
  TemplateEnvironmentQuery,
  TemplateEnvironmentData,
  TemplateEnvironmentVars,
} from "./queries";
import { NewTemplateAsset } from "./template-asset-form";
import { UpdateTemplateModal } from "./template-form";
import { useBoolean } from "client-lib";

const FieldHeaderRow = styled(HeaderRow)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const FieldRow = styled(BaseRow)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

function Wrapper({
  children,
  template,
  templateId,
}: {
  children?: ReactNode;
  template?: Template;
  templateId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Шаблоны", url: "/templates" },
          { title: template ? template.title : `${templateId}` },
        ]}
      />
      <h1>
        Шаблон {template ? `\u00ab${template.title}\u00bb` : `${templateId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function TemplatePage() {
  const [
    isUpdateTemplateOpen,
    { setTrue: openUpdateTemplate, setFalse: closeUpdateTemplate },
  ] = useBoolean(false);
  const { params } = useRouteMatch<{ templateId: string }>();
  const { data, loading, error, refetch } = useQuery<
    TemplateEnvironmentData,
    TemplateEnvironmentVars
  >(TemplateEnvironmentQuery, {
    variables: {
      templateId: params.templateId,
    },
  });

  if (loading) {
    return (
      <Wrapper templateId={params.templateId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper templateId={params.templateId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper templateId={params.templateId} />;
  }

  const template = data.TemplateEnvironment.template;
  return (
    <Wrapper templateId={params.templateId} template={template}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Тип</TH>
            <TD>{template.code}</TD>
          </TR>
          <TR>
            <TH>Название</TH>
            <TD>{template.title}</TD>
          </TR>
          <TR>
            <TH>Картинка</TH>
            <TD>{template.image}</TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <Button onClick={openUpdateTemplate}>Редактировать</Button>
      <br />
      <h2>Ассеты</h2>
      <List>
        <FieldHeaderRow>
          <HeaderCell>Тип</HeaderCell>
          <HeaderCell>Переменная</HeaderCell>
          <HeaderCell>Массив?</HeaderCell>
          <HeaderCell>Материал</HeaderCell>
        </FieldHeaderRow>
        {data.TemplateEnvironment.assets.map((asset: TemplateAssetExpanded) => {
          const url = `/templates/${params.templateId}/${asset.id}`;
          return (
            <FieldRow key={asset.id}>
              <ListLinkCell to={url}>{asset.type}</ListLinkCell>
              <ListLinkCell to={url}>{asset.name}</ListLinkCell>
              <ListLinkCell to={url}>
                {asset.isArray ? "Да" : "Нет"}
              </ListLinkCell>
              <ListLinkCell to={url}>
                {asset.materialExpanded?.title || ""}
              </ListLinkCell>
            </FieldRow>
          );
        })}
      </List>
      <h2>Добавить ассет</h2>
      <NewTemplateAsset
        templateId={template.id}
        ordering={
          data.TemplateEnvironment.assets.length > 0
            ? data.TemplateEnvironment.assets[
                data.TemplateEnvironment.assets.length - 1
              ].ordering + 1
            : 1
        }
        onCompleted={() => refetch()}
      />
      {isUpdateTemplateOpen && (
        <UpdateTemplateModal
          template={template}
          onCloseRequest={closeUpdateTemplate}
        />
      )}
    </Wrapper>
  );
}
