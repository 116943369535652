import React, { useCallback, useMemo } from "react";
import { omit, pick } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { TemplatePresetInput } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib/ui";
import { PresetForm } from "./preset-form";
import {
  UpdateTemplatePreset,
  UpdateTemplatePresetVars,
  UpdateTemplatePresetData,
} from "../queries";
import {
  Templates,
  TemplatesData,
  TemplatesVars,
} from "src/features/template/queries";
import { TemplatePresetEnvironment } from "client-lib/fragments";

interface Props {
  templatePresetEnvironment: TemplatePresetEnvironment;
  onCloseRequest: () => any;
  onUpdated: () => void;
}

export function UpdateTemplatePresetModal({
  templatePresetEnvironment,
  onCloseRequest,
  onUpdated,
}: Props) {
  const { data } = useQuery<TemplatesData, TemplatesVars>(Templates);
  const [update, { loading, error }] = useMutation<
    UpdateTemplatePresetData,
    UpdateTemplatePresetVars
  >(UpdateTemplatePreset);

  const input = useMemo<TemplatePresetInput>(() => {
    return {
      ...pick(
        templatePresetEnvironment.templatePreset,
        "id",
        "ordering",
        "isPublic",
        "templateId",
        "title",
        "description",
        "templateVariables"
      ),
      images: templatePresetEnvironment.images.map((presetImage) =>
        pick(presetImage, "id", "ordering", "imageId", "title")
      ),
    };
  }, [templatePresetEnvironment]);

  const handleSubmit = useCallback(
    async (formInput: TemplatePresetInput) => {
      await update({
        variables: {
          templatePresetId: templatePresetEnvironment.id,
          patch: {
            ...omit(formInput, "id", "templateId"),
          },
        },
      });
      onUpdated();
      onCloseRequest();
    },
    [templatePresetEnvironment, update, onCloseRequest]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title={"Редактировать пресет"}>
      {error ? <ParseError error={error} /> : null}
      <PresetForm
        initialValues={input}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Сохранить"}
        lockTemplate
        templates={data?.Templates}
      />
    </Modal>
  );
}
