import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import { TemplateCode, TemplateInput, Template } from "shared";
import { ParseError } from "src/lib";
import { TemplateForm } from "./template-form";
import {
  CreateTemplate,
  CreateTemplateVars,
  CreateTemplateData,
} from "../queries";

interface Props {
  ordering: number;
  onCompleted: (template: Template) => any;
}

export function NewTemplate({ ordering, onCompleted }: Props) {
  const [create, { loading, error }] = useMutation<
    CreateTemplateData,
    CreateTemplateVars
  >(CreateTemplate);
  const handleSubmit = useCallback(
    async (input: TemplateInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateTemplate);
    },
    [create, onCompleted]
  );

  return (
    <>
      {error ? <ParseError error={error} /> : null}
      <TemplateForm
        initialValues={{
          id: v4(),
          title: "",
          code: TemplateCode.TABLE_LOFT_1,
          ordering,
          image: null,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Создать"}
      />
    </>
  );
}
