import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { CategoriesPage } from "./categories-page";

export function CategoryRouter() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CategoriesPage} />
    </Switch>
  );
}
