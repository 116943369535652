import React, { useCallback, MouseEvent } from "react";
import { Image } from "shared";
import { getImageUrl, grey20, gutters } from "client-lib";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageContainer = styled(Link)`
  width: 200px;
  height: 200px;

  margin: ${gutters.md}px;
  padding: ${gutters.md}px;
  background-color: ${grey20};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

interface Props {
  images: Image[];
  onClick?: (image: Image) => void;
}

export function ImageList({ images, onClick }: Props) {
  const handleImageClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, image: Image) => {
      if (!onClick) {
        return;
      }
      event.preventDefault();
      onClick(image);
    },
    [onClick]
  );

  return (
    <ListContainer>
      {images.map((image: Image) => (
        <ImageContainer
          key={image.id}
          to={`/images/${image.id}`}
          onClick={(event) => handleImageClick(event, image)}
        >
          <img src={getImageUrl(image.id)} />
        </ImageContainer>
      ))}
    </ListContainer>
  );
}
