import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import { TechProcess, techProcessCodeSchema, TechProcessPool } from "shared";
import { Breadcrumbs, Spinner, TBody, TR, TH, TD, Table } from "client-lib/ui";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  TechProcessPricing,
  TechProcessPricingData,
  TechProcessPricingVars,
} from "./queries";
import { PricingCostsEditor } from "../pricing";

function Wrapper({
  children,
  techProcess,
  techProcessCode,
}: {
  children?: ReactNode;
  techProcess?: TechProcess;
  techProcessCode: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Техпроцессы", url: "/tech-processes" },
          { title: techProcess ? techProcess.title : `${techProcessCode}` },
        ]}
      />
      <h1>
        Техпроцесс{" "}
        {techProcess
          ? `\u00ab${techProcess.title}\u00bb`
          : `${techProcessCode}`}
      </h1>
      {children}
    </Layout>
  );
}

export function TechProcessPage() {
  const { params } = useRouteMatch<{ code: string }>();
  const techProcessCode = techProcessCodeSchema.parse(params.code);
  const techProcess = TechProcessPool[techProcessCode];
  const { data, loading, error, refetch } = useQuery<
    TechProcessPricingData,
    TechProcessPricingVars
  >(TechProcessPricing, {
    variables: {
      techProcessCode,
    },
  });

  if (loading) {
    return (
      <Wrapper techProcessCode={techProcessCode}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper techProcessCode={techProcessCode}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper techProcessCode={techProcessCode} />;
  }

  const pricingCosts = data.TechProcessPricing;
  return (
    <Wrapper techProcessCode={techProcessCode} techProcess={techProcess}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Код</TH>
            <TD>{techProcess.code}</TD>
          </TR>
          <TR>
            <TH>Название</TH>
            <TD>{techProcess.title}</TD>
          </TR>
          <TR>
            <TH>Базовая единица</TH>
            <TD>{techProcess.basicUnit}</TD>
          </TR>
          <TR>
            <TH>Время на настройку, сек</TH>
            <TD>{techProcess.settingUpSeconds}</TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <h2>Цены (себестоимость) за базовую единицу</h2>
      <PricingCostsEditor
        techProcessCode={techProcessCode}
        materialId={null}
        pricingCosts={pricingCosts}
        onCompleted={() => refetch()}
      />
    </Wrapper>
  );
}
