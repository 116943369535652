import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import { ImageInput, Image } from "shared";
import { ParseError } from "src/lib";
import { ImageForm } from "./image-form";
import { CreateImage, CreateImageVars, CreateImageData } from "../queries";

interface Props {
  onCompleted: (image: Image) => any;
}

export function NewImage({ onCompleted }: Props) {
  const [create, { loading, error }] = useMutation<
    CreateImageData,
    CreateImageVars
  >(CreateImage);
  const handleSubmit = useCallback(
    async (input: ImageInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateImage);
    },
    [create, onCompleted]
  );

  return (
    <>
      {error ? <ParseError error={error} /> : null}
      <ImageForm
        initialValues={{
          id: v4(),
          mimeType: "image/jpeg",
          originalWidth: 0,
          originalHeight: 0,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Создать"}
      />
    </>
  );
}
