import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import { Material } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
} from "client-lib/ui";
import { useBoolean } from "client-lib/services";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  MaterialEnvironmentQuery,
  MaterialEnvironmentData,
  MaterialEnvironmentVars,
} from "./queries";
import { PricingCostsEditor } from "../pricing";
import { UpdateMaterialModal } from "./material-form";

function Wrapper({
  children,
  material,
  materialId,
}: {
  children?: ReactNode;
  material?: Material;
  materialId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Материалы", url: "/materials" },
          { title: material ? material.title : `${materialId}` },
        ]}
      />
      <h1>
        Материал {material ? `\u00ab${material.title}\u00bb` : `${materialId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function MaterialPage() {
  const [
    isUpdateMaterialOpen,
    { setTrue: openUpdateMaterial, setFalse: closeUpdateMaterial },
  ] = useBoolean(false);
  const { params } = useRouteMatch<{ materialId: string }>();
  const { data, loading, error, refetch } = useQuery<
    MaterialEnvironmentData,
    MaterialEnvironmentVars
  >(MaterialEnvironmentQuery, {
    variables: {
      materialId: params.materialId,
    },
  });

  if (loading) {
    return (
      <Wrapper materialId={params.materialId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper materialId={params.materialId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper materialId={params.materialId} />;
  }

  const { material, pricingCosts } = data.MaterialEnvironment;
  return (
    <Wrapper materialId={params.materialId} material={material}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Тип</TH>
            <TD>{material.type}</TD>
          </TR>
          <TR>
            <TH>Название</TH>
            <TD>{material.title}</TD>
          </TR>
          <TR>
            <TH>Короткое название для селектора</TH>
            <TD>{material.pickerTitle}</TD>
          </TR>
          <TR>
            <TH>Базовая единица</TH>
            <TD>{material.basicUnit}</TD>
          </TR>
          <TR>
            <TH>Масса одной единицы, кг</TH>
            <TD>{material.unitMassKg}</TD>
          </TR>
          <TR>
            <TH>Размер профиля 1 (ширина), мм</TH>
            <TD>{material.mmDimension1}</TD>
          </TR>
          <TR>
            <TH>Размер профиля 2 (высота), мм</TH>
            <TD>{material.mmDimension2}</TD>
          </TR>
          <TR>
            <TH>Размер профиля 3 (толщина), мм</TH>
            <TD>{material.mmDimension3}</TD>
          </TR>
        </TBody>
      </Table>
      <Button onClick={openUpdateMaterial}>Редактировать материал</Button>
      <br />
      <h2>Цены (себестоимость) за базовую единицу</h2>
      <PricingCostsEditor
        materialId={material.id}
        techProcessCode={null}
        pricingCosts={pricingCosts}
        onCompleted={() => refetch()}
      />
      {isUpdateMaterialOpen && (
        <UpdateMaterialModal
          material={material}
          onCloseRequest={closeUpdateMaterial}
        />
      )}
    </Wrapper>
  );
}
