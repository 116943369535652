import React from "react";
import styled from "@emotion/styled";
import { MaterialInput, MaterialType, Texture, UnitType } from "shared";
import { Button, TextField, FloatField, SelectField } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { materialInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const SelectWrapper = styled.label`
  display: block;
  margin-top: ${gutters.md}px;
`;

function TextureField({
  name,
  textures,
}: {
  name: string;
  textures: Texture[];
}) {
  return (
    <SelectField name={name}>
      <option value={""}>Не выбрано</option>
      {textures.map((texture) => (
        <option key={texture.id} value={texture.id}>
          {texture.title}
        </option>
      ))}
    </SelectField>
  );
}

interface Props {
  initialValues: MaterialInput;
  onSubmit: (values: MaterialInput) => any;
  disabled?: boolean;
  submitTitle?: string;
  textures: Texture[];
}

export function MaterialForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
  textures,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={materialInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<MaterialInput>) => (
        <Form>
          <SelectWrapper>
            Тип материала
            <SelectField name={"type"}>
              {Object.values(MaterialType).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </SelectField>
          </SelectWrapper>
          <TextField
            label={"Название"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"title"}
          />
          <TextField
            label={"Короткое название для селектора"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"pickerTitle"}
          />
          <SelectWrapper>
            Базовая единица
            <SelectField name={"basicUnit"}>
              {Object.values(UnitType).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </SelectField>
          </SelectWrapper>
          <FloatField
            label={"Масса одной единицы, кг"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"unitMassKg"}
          />
          <FloatField
            label={"Размер профиля 1 (ширина), мм"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"mmDimension1"}
          />
          <FloatField
            label={"Размер профиля 2 (высота), мм"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"mmDimension2"}
          />
          <FloatField
            label={"Размер профиля 3 (толщина), мм"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"mmDimension3"}
          />
          <TextField
            label={"Флаги"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"customFlags"}
          />
          <SelectWrapper>
            Текстура 1
            <TextureField name={"texture1Id"} textures={textures} />
          </SelectWrapper>
          <SelectWrapper>
            Текстура 2
            <TextureField name={"texture2Id"} textures={textures} />
          </SelectWrapper>
          <SelectWrapper>
            Текстура 3
            <TextureField name={"texture3Id"} textures={textures} />
          </SelectWrapper>
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Сохранить"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
