import React from "react";
import styled from "@emotion/styled";
import { CategoryInput } from "shared";
import { Button, TextField } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { categoryInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: CategoryInput;
  onSubmit: (values: CategoryInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function CategoryForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={categoryInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<CategoryInput>) => (
        <Form>
          <TextField
            label={"Название"}
            controlProps={{ size: 50 }}
            disabled={disabled}
            name={"title"}
          />
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Сохранить"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
