import React, { ReactNode, useMemo } from "react";
import { ErrorCode } from "shared";
import { ApolloError } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { Error } from "./error";

interface ErrorData {
  code: string;
  message: string;
}

interface ErrorConfig {
  message?: ReactNode;
  messageComponent?: (props: { errorData: ErrorData }) => JSX.Element;
  redirectTo?: string;
}

const defaultConfig: Partial<Record<string, ErrorConfig>> = {
  UNKNOWN: {
    messageComponent: (props: { errorData: ErrorData }) => (
      <Error>{props.errorData.message}</Error>
    ),
  },
  [ErrorCode.UNAUTHENTICATED]: {
    redirectTo: "/login",
  },
  [ErrorCode.FORBIDDEN]: {
    message: "У вас недостаточно прав для доступа к этой странице",
  },
};

export function ParseError({
  error,
  config,
}: {
  error: ApolloError;
  config?: Record<string, ErrorConfig>;
}) {
  const errorData = useMemo<ErrorData>(() => {
    if (error.networkError) {
      return {
        code: "NETWORK",
        message:
          "Ошибка соединения с сервером. Пожалуйта, проверьте подключение к Интернету и повторите попытку.",
      };
    }
    const gError = error.graphQLErrors[0];
    if (!gError) {
      return {
        code: "UNKNOWN",
        message:
          "Произошла неизвестная ошибка. Пожалуйста, повторите попытку позднее.",
      };
    }
    return {
      code: String(gError.extensions?.["code"]),
      message: gError.message,
    };
  }, [error]);

  const configData = useMemo<ErrorConfig>(() => {
    return (
      config?.[errorData.code] ||
      defaultConfig[errorData.code] ||
      config?.["UNKNOWN"] ||
      defaultConfig.UNKNOWN!
    );
  }, [errorData, config]);

  if (configData.redirectTo) {
    return <Redirect to={configData.redirectTo} />;
  }

  if (configData.messageComponent) {
    return <configData.messageComponent errorData={errorData} />;
  }

  if (configData.message) {
    return <>{configData.message}</>;
  }

  return <Error>Произошла непредвиденная ошибка</Error>;
}
