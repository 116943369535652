import gql from "graphql-tag";
import {
  materialSchema,
  Material,
  MaterialInput,
  MaterialPatch,
  pricingCostSchema,
  PricingCost,
} from "shared";
import { getFieldsFromSchema } from "client-lib/services";

export const MATERIAL_FULL_FRAGMENT = gql`
  fragment MaterialFullFragment on Material {
    ${getFieldsFromSchema(materialSchema)}
  }
`;

export const PRICING_COST_FULL_FRAGMENT = gql`
  fragment PricingCostFullFragment on PricingCost {
    ${getFieldsFromSchema(pricingCostSchema)}
  }
`;

export const AdminMaterials = gql`
  query AdminMaterials {
    AdminMaterials {
      ...MaterialFullFragment
    }
  }
  ${MATERIAL_FULL_FRAGMENT}
`;

export interface AdminMaterialsVars {}

export type AdminMaterialsData = {
  AdminMaterials: Material[];
};

export const CreateMaterial = gql`
  mutation CreateMaterial($input: MaterialInput!) {
    CreateMaterial(input: $input) {
      ...MaterialFullFragment
    }
  }
  ${MATERIAL_FULL_FRAGMENT}
`;

export interface CreateMaterialVars {
  input: MaterialInput;
}

export type CreateMaterialData = {
  CreateMaterial: Material;
};

export const UpdateMaterial = gql`
  mutation UpdateMaterial($materialId: String!, $patch: MaterialPatch!) {
    UpdateMaterial(materialId: $materialId, patch: $patch) {
      ...MaterialFullFragment
    }
  }
  ${MATERIAL_FULL_FRAGMENT}
`;

export interface UpdateMaterialVars {
  materialId: string;
  patch: MaterialPatch;
}

export type UpdateMaterialData = {
  UpdateMaterial: Material;
};

export const MaterialEnvironmentQuery = gql`
  query MaterialEnvironment($materialId: String!) {
    MaterialEnvironment(materialId: $materialId) {
      material {
        ...MaterialFullFragment
      }
      pricingCosts {
        ...PricingCostFullFragment
      }
    }
  }
  ${MATERIAL_FULL_FRAGMENT}
  ${PRICING_COST_FULL_FRAGMENT}
`;

export interface MaterialEnvironmentVars {
  materialId: string;
}

export type MaterialEnvironmentData = {
  MaterialEnvironment: {
    material: Material;
    pricingCosts: PricingCost[];
  };
};
