import gql from "graphql-tag";
import {
  TemplatePreset,
  TemplatePresetInput,
  TemplatePresetPatch,
} from "shared";
import {
  TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT,
  TEMPLATE_PRESET_FULL_FRAGMENT,
  TemplatePresetEnvironment,
} from "client-lib/fragments";

export const TemplatePresetEnvironments = gql`
  query TemplatePresetEnvironments {
    TemplatePresetEnvironments {
      ...TemplatePresetEnvironmentFullFragment
    }
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface TemplatePresetEnvironmentsVars {}

export type TemplatePresetEnvironmentsData = {
  TemplatePresetEnvironments: TemplatePresetEnvironment[];
};

export const TemplatePresetEnvironmentQuery = gql`
  query TemplatePresetEnvironment($templatePresetId: String!) {
    TemplatePresetEnvironment(templatePresetId: $templatePresetId) {
      ...TemplatePresetEnvironmentFullFragment
    }
  }
  ${TEMPLATE_PRESET_ENVIRONMENT_FULL_FRAGMENT}
`;

export interface TemplatePresetEnvironmentVars {
  templatePresetId: string;
}

export type TemplatePresetEnvironmentData = {
  TemplatePresetEnvironment: TemplatePresetEnvironment;
};

export const CreateTemplatePreset = gql`
  mutation CreateTemplatePreset($input: TemplatePresetInput!) {
    CreateTemplatePreset(input: $input) {
      ...TemplatePresetFullFragment
    }
  }
  ${TEMPLATE_PRESET_FULL_FRAGMENT}
`;

export interface CreateTemplatePresetVars {
  input: TemplatePresetInput;
}

export type CreateTemplatePresetData = {
  CreateTemplatePreset: TemplatePreset;
};

export const UpdateTemplatePreset = gql`
  mutation UpdateTemplatePreset(
    $templatePresetId: String!
    $patch: TemplatePresetPatch!
  ) {
    UpdateTemplatePreset(templatePresetId: $templatePresetId, patch: $patch) {
      ...TemplatePresetFullFragment
    }
  }
  ${TEMPLATE_PRESET_FULL_FRAGMENT}
`;

export interface UpdateTemplatePresetVars {
  templatePresetId: string;
  patch: TemplatePresetPatch;
}

export type UpdateTemplatePresetData = {
  UpdateTemplatePreset: TemplatePreset;
};
