import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import { pick } from "lodash";
import { decode } from "jsonwebtoken";
import { tokenSchemaWithMeta, TokenUser } from "shared";

const LOCAL_STORAGE_KEY_TOKEN = "auth-token";

type AuthenticatedData = {
  isAuthenticated: true;
  token: string;
  user: TokenUser;
};
type NotAuthenticatedData = {
  isAuthenticated: false;
  token: null;
  user: TokenUser | null; // still can have user here, e.g. after token has expired
};

type AuthValue = {
  setToken: (token: string) => void;
  logout: () => void;
} & (NotAuthenticatedData | AuthenticatedData);

const AuthContext = React.createContext<AuthValue>({
  setToken: () => {},
  logout: () => {},
  isAuthenticated: false,
  user: null,
  token: null,
});

export function useAuth(): AuthValue {
  return useContext(AuthContext);
}

export function useAuthData(): AuthenticatedData {
  const authValue = useContext(AuthContext);
  if (!authValue.isAuthenticated) {
    throw new Error("Expected authenticated user");
  }
  return pick(
    authValue,
    "isAuthenticated",
    "token",
    "user"
  ) as AuthenticatedData;
}

function extractDataFromToken(token: string | null): {
  user: TokenUser | null;
  authExpiredAt: Date | null;
  isAuthenticated: boolean;
  token: string | null;
} {
  try {
    if (token === null) {
      return {
        user: null,
        authExpiredAt: null,
        isAuthenticated: false,
        token,
      };
    }
    const decoded = decode(token, { json: true });
    const parsed = tokenSchemaWithMeta.parse(decoded);
    return {
      user: parsed.user,
      authExpiredAt: new Date(parsed.exp * 1000),
      isAuthenticated: true,
      token,
    };
  } catch (err) {
    return {
      user: null,
      authExpiredAt: null,
      isAuthenticated: false,
      token: null,
    };
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const initialValues = extractDataFromToken(
    localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN)
  );
  const [token, setToken] = useState<string | null>(initialValues.token);
  const [user, setUser] = useState<TokenUser | null>(initialValues.user);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    initialValues.isAuthenticated
  );
  const [authExpiredAt, setAuthExpiredAt] = useState<Date | null>(
    initialValues.authExpiredAt
  );

  useEffect(
    function setExpirationTimeout() {
      const now = new Date();
      let timer: number | null = null;
      if (authExpiredAt && authExpiredAt > now) {
        setIsAuthenticated(true);
        const timeout = authExpiredAt.getTime() - now.getTime();
        if (timeout <= 0x7fffffff) {
          setTimeout(() => {
            setIsAuthenticated(false);
            setToken(null);
          }, timeout);
        }
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    },
    [authExpiredAt, setIsAuthenticated]
  );

  const handleSetToken = useCallback(
    (
      token: string,
      { saveToLocalStorage = true }: { saveToLocalStorage?: boolean } = {}
    ) => {
      if (saveToLocalStorage) {
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, token);
      }

      setToken(token);

      const { user, authExpiredAt } = extractDataFromToken(token);
      if (!user) {
        throw new Error(`Auth token parsing error: ${token}`);
      }
      setAuthExpiredAt(authExpiredAt);
      setUser(user);
    },
    [setToken, setAuthExpiredAt, setUser]
  );

  const handleLogout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);

    setToken(null);
    setAuthExpiredAt(null);
    setUser(null);
    setIsAuthenticated(false);
  }, [setToken, setAuthExpiredAt, setUser]);

  const value = useMemo<AuthValue>(() => {
    return {
      isAuthenticated,
      user,
      token,
      setToken: handleSetToken,
      logout: handleLogout,
    } as AuthValue;
  }, [isAuthenticated, user, token, handleSetToken]);

  useEffect(
    function onLoad() {
      const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
      if (token === null) {
        return;
      }

      try {
        handleSetToken(token, { saveToLocalStorage: false });
      } catch (err) {}
    },
    [handleSetToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
