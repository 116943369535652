import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Material } from "shared";
import { Spinner } from "client-lib/ui";
import { Layout } from "src/features/layout";
import {
  AdminMaterials,
  AdminMaterialsData,
  AdminMaterialsVars,
} from "./queries";
import { NewMaterial } from "./material-form";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
  cutId,
} from "src/lib";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Материалы</h1>
      {children}
    </Layout>
  );
}

const columnTemplate =
  "0.3fr 0.7fr 1fr 0.6fr 0.6fr 0.5fr  0.5fr 0.5fr 0.5fr 0.5fr";

export function MaterialsPage() {
  const { data, loading, error, refetch } = useQuery<
    AdminMaterialsData,
    AdminMaterialsVars
  >(AdminMaterials);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Тип</HeaderCell>
          <HeaderCell>Название</HeaderCell>
          <HeaderCell>Единица</HeaderCell>
          <HeaderCell>Масса&nbsp;единицы</HeaderCell>
          <HeaderCell>Профиль 1</HeaderCell>
          <HeaderCell>Профиль 2</HeaderCell>
          <HeaderCell>Текстура 1</HeaderCell>
          <HeaderCell>Текстура 2</HeaderCell>
          <HeaderCell>Текстура 3</HeaderCell>
        </HeaderRow>
        {data.AdminMaterials.map((material: Material) => {
          const url = `/materials/${material.id}`;
          return (
            <ListRow key={material.id} columnTemplate={columnTemplate}>
              <ListLinkCell to={url}>{cutId(material.id)}</ListLinkCell>
              <ListLinkCell to={url}>{material.type}</ListLinkCell>
              <ListLinkCell to={url}>{material.title}</ListLinkCell>
              <ListLinkCell to={url}>{material.basicUnit}</ListLinkCell>
              <ListLinkCell to={url}>{material.unitMassKg}</ListLinkCell>
              <ListLinkCell to={url}>{material.mmDimension1}</ListLinkCell>
              <ListLinkCell to={url}>{material.mmDimension2}</ListLinkCell>
              <ListLinkCell to={url}>{cutId(material.texture1Id)}</ListLinkCell>
              <ListLinkCell to={url}>{cutId(material.texture2Id)}</ListLinkCell>
              <ListLinkCell to={url}>{cutId(material.texture3Id)}</ListLinkCell>
            </ListRow>
          );
        })}
      </List>
      <h2>Создать новый материал</h2>
      <NewMaterial onCompleted={refetch} />
    </Wrapper>
  );
}
