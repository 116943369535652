import React, { ReactNode, useCallback } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import { User } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
} from "client-lib/ui";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  UserEnvironmentQuery,
  UserEnvironmentData,
  UserEnvironmentVars,
  UserToken,
  UserTokenData,
  UserTokenVars,
} from "./queries";

function Wrapper({
  children,
  user,
  userId,
}: {
  children?: ReactNode;
  user?: User;
  userId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Пользователи", url: "/users" },
          { title: user ? `${user.nameFirst} ${user.nameLast}` : `${userId}` },
        ]}
      />
      <h1>
        Пользователь{" "}
        {user ? `\u00ab${user.nameFirst} ${user.nameLast}\u00bb` : `${userId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function UserPage() {
  const { params } = useRouteMatch<{ userId: string }>();
  const { data, loading, error } = useQuery<
    UserEnvironmentData,
    UserEnvironmentVars
  >(UserEnvironmentQuery, {
    variables: {
      userId: params.userId,
    },
  });

  const [fetchToken] = useLazyQuery<UserTokenData, UserTokenVars>(UserToken, {
    variables: {
      userId: params.userId,
    },
  });

  const handleAuthenticate = useCallback(() => {
    fetchToken({
      onCompleted: (data: UserTokenData) => {
        debugger;
        window.location.href = `${process.env.REACT_APP_METAFABRIKA_HOST}/account?token=${data.UserToken}`;
      },
    });
  }, [fetchToken]);

  if (loading) {
    return (
      <Wrapper userId={params.userId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper userId={params.userId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper userId={params.userId} />;
  }

  const { user, orders } = data.UserEnvironment;
  return (
    <Wrapper userId={params.userId} user={user}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{user.id}</TD>
          </TR>
          <TR>
            <TH>Дата регистрации</TH>
            <TD>{new Date(user.createdAt).toLocaleString()}</TD>
          </TR>
          <TR>
            <TH>Имя</TH>
            <TD>{user.nameFirst}</TD>
          </TR>
          <TR>
            <TH>Фамилия</TH>
            <TD>{user.nameLast}</TD>
          </TR>
          <TR>
            <TH>Администратор?</TH>
            <TD>{user.isAdmin ? "Да" : "Нет"}</TD>
          </TR>
        </TBody>
      </Table>

      <Button onClick={handleAuthenticate}>Войти как этот пользователь</Button>
      <br />
      <h2>Заказы</h2>
      {JSON.stringify(orders)}
    </Wrapper>
  );
}
