import React from "react";
import { TechProcessCode, TechProcessPool } from "shared";
import { Layout } from "src/features/layout";
import { HeaderCell, HeaderRow, List, ListLinkCell, ListRow } from "src/lib";

const columnTemplate = "1fr 2fr 0.3fr 0.3fr";

export function TechProcessesPage() {
  return (
    <Layout>
      <h1>Техпроцессы</h1>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>Код</HeaderCell>
          <HeaderCell>Название</HeaderCell>
          <HeaderCell>Единица</HeaderCell>
          <HeaderCell>Настройка, с</HeaderCell>
        </HeaderRow>
        {Object.values(TechProcessCode).map(
          (techProcessCode: TechProcessCode) => {
            const techProcess = TechProcessPool[techProcessCode];
            const url = `/tech-processes/${techProcessCode}`;
            return (
              <ListRow key={techProcessCode} columnTemplate={columnTemplate}>
                <ListLinkCell to={url}>{techProcess.code}</ListLinkCell>
                <ListLinkCell to={url}>{techProcess.title}</ListLinkCell>
                <ListLinkCell to={url}>{techProcess.basicUnit}</ListLinkCell>
                <ListLinkCell to={url}>
                  {techProcess.settingUpSeconds}
                </ListLinkCell>
              </ListRow>
            );
          }
        )}
      </List>
    </Layout>
  );
}
