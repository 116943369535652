import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import { TemplatePreset } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
  useBoolean,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  TemplatePresetEnvironmentQuery,
  TemplatePresetEnvironmentData,
  TemplatePresetEnvironmentVars,
} from "./queries";
import { PresetImages } from "./preset-images";
import { UpdateTemplatePresetModal } from "./preset-form";

function Wrapper({
  children,
  preset,
  presetId,
}: {
  children?: ReactNode;
  preset?: TemplatePreset;
  presetId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Шаблоны", url: "/templates" },
          { title: "Пресеты", url: "/templates/presets" },
          { title: preset ? preset.title : `${presetId}` },
        ]}
      />
      <h1>Пресет {preset ? `\u00ab${preset.title}\u00bb` : `${presetId}`}</h1>
      {children}
    </Layout>
  );
}

export function PresetPage() {
  const [
    isUpdatePresetOpen,
    { setTrue: openUpdatePreset, setFalse: closeUpdatePreset },
  ] = useBoolean(false);

  const { params } = useRouteMatch<{ presetId: string }>();
  const { data, loading, error, refetch } = useQuery<
    TemplatePresetEnvironmentData,
    TemplatePresetEnvironmentVars
  >(TemplatePresetEnvironmentQuery, {
    variables: {
      templatePresetId: params.presetId,
    },
  });

  if (loading) {
    return (
      <Wrapper presetId={params.presetId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper presetId={params.presetId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper presetId={params.presetId} />;
  }

  const {
    templatePreset: preset,
    images,
    template,
  } = data.TemplatePresetEnvironment;

  return (
    <Wrapper presetId={params.presetId} preset={preset}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Название</TH>
            <TD>{preset.title}</TD>
          </TR>
          <TR>
            <TH>Описание</TH>
            <TD>{preset.description}</TD>
          </TR>
          <TR>
            <TH>Название шаблона</TH>
            <TD>{template.title}</TD>
          </TR>
          <TR>
            <TH>Конфигурация</TH>
            <TD>
              <Button
                size={"XS"}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_METAFABRIKA_HOST}/templates/${params.presetId}`;
                }}
              >
                Перейти в редактор
              </Button>
            </TD>
          </TR>
          <TR>
            <TH>Изображения</TH>
            <TD>
              <PresetImages presetImages={images} />
            </TD>
          </TR>
          <TR>
            <TH>Переменные шаблона</TH>
            <TD>{preset.templateVariables}</TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <Button onClick={openUpdatePreset}>Редактировать</Button>
      <br />
      {isUpdatePresetOpen && (
        <UpdateTemplatePresetModal
          templatePresetEnvironment={data.TemplatePresetEnvironment}
          onCloseRequest={closeUpdatePreset}
          onUpdated={refetch}
        />
      )}
    </Wrapper>
  );
}
