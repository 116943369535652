import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { TemplatePresetImageInput } from "shared";
import {
  gutters,
  white,
  disabledGrey,
  getImageUrl,
  typographyBody2,
  CloseIcon,
  IconButton,
} from "client-lib";

const Container = styled.div``;

const Item = styled.div`
  position: relative;
  margin: ${gutters.md}px 0;
  height: 100px;
  display: flex;
  align-items: stretch;
  background-color: ${white};
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: ${gutters.sm}px;
  top: ${gutters.sm}px;
`;

const ItemControls = styled.div`
  flex: 1 1 100%;
  padding: ${gutters.md}px;
`;

const EmptyMessage = styled.div([
  css`
    height: 100px;
    color: ${disabledGrey};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  typographyBody2,
]);

interface Props {
  presetImages: TemplatePresetImageInput[];
  onRemove?: (index: number) => void;
  emptyMessage?: string;
  controls?: (
    presetImage: TemplatePresetImageInput,
    index: number
  ) => ReactNode;
}

function defaultControls(
  presetImage: TemplatePresetImageInput,
  _index: number
) {
  return <div>{presetImage.title}</div>;
}

export function PresetImages({
  presetImages,
  onRemove,
  emptyMessage,
  controls = defaultControls,
}: Props) {
  return (
    <Container>
      {presetImages.length === 0 && <EmptyMessage>{emptyMessage}</EmptyMessage>}
      {presetImages.map(
        (presetImageInput: TemplatePresetImageInput, index: number) => (
          <Item key={presetImageInput.id}>
            <ImageContainer>
              <img src={getImageUrl(presetImageInput.imageId)} />
            </ImageContainer>
            <ItemControls>{controls(presetImageInput, index)}</ItemControls>
            {onRemove && (
              <CloseIconButton onClick={() => onRemove(index)}>
                <CloseIcon />
              </CloseIconButton>
            )}
          </Item>
        )
      )}
    </Container>
  );
}
