import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { v4 } from "uuid";
import { TemplatePresetInput, TemplatePreset } from "shared";
import { Modal } from "client-lib/ui";
import { ParseError } from "src/lib";
import { PresetForm } from "./preset-form";
import {
  CreateTemplatePreset,
  CreateTemplatePresetVars,
  CreateTemplatePresetData,
} from "../queries";
import { Templates, TemplatesData, TemplatesVars } from "../../queries";

interface Props {
  onCloseRequest: () => any;
  ordering: number;
  onCompleted: (templatePreset: TemplatePreset) => any;
}

export function NewPresetModal({
  onCloseRequest,
  ordering,
  onCompleted,
}: Props) {
  const { data } = useQuery<TemplatesData, TemplatesVars>(Templates);
  const [create, { loading, error }] = useMutation<
    CreateTemplatePresetData,
    CreateTemplatePresetVars
  >(CreateTemplatePreset);
  const handleSubmit = useCallback(
    async (input: TemplatePresetInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateTemplatePreset);
    },
    [create, onCompleted]
  );

  return (
    <Modal
      onCloseRequest={onCloseRequest}
      title={"Создать новый пресет"}
      minWidth={400}
    >
      {error ? <ParseError error={error} /> : null}
      <PresetForm
        initialValues={{
          id: v4(),
          ordering,
          templateId: "",
          isPublic: false,
          title: "",
          description: "",
          images: [],
          templateVariables: null,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Создать"}
        templates={data?.Templates}
      />
    </Modal>
  );
}
