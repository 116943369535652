import React from "react";
import styled from "@emotion/styled";
import { TextureInput } from "shared";
import { Button, TextField, IntField, FloatField } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { textureInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: TextureInput;
  onSubmit: (values: TextureInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function TextureForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={textureInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<TextureInput>) => (
        <Form>
          <TextField
            label={"Название"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"title"}
          />
          <TextField
            label={"Путь"}
            controlProps={{ size: 100 }}
            disabled={disabled}
            name={"path"}
          />
          <IntField label={"Ширина"} disabled={disabled} name={"mmWidth"} />
          <IntField label={"Высота"} disabled={disabled} name={"mmHeight"} />
          <FloatField
            label={"Металличность"}
            disabled={disabled}
            name={"metalness"}
          />
          <FloatField
            label={"Шероховатость"}
            disabled={disabled}
            name={"roughness"}
          />
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Сохранить"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
