import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { TechProcessesPage } from "./tech-processes-page";
import { TechProcessPage } from "./tech-process-page";

export function TechProcessRouter() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={TechProcessesPage} />
      <Route exact path={`${path}/:code`} component={TechProcessPage} />
    </Switch>
  );
}
