import React from "react";
import styled from "@emotion/styled";
import { TemplateAssetType, TemplateAssetInput, Material } from "shared";
import { Button, TextField, SelectField, CheckboxField } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { templateAssetInputFormikSchema } from "./types";

const Container = styled.div`
  width: 300px;
  input,
  select {
    width: 100%;
  }
  form > div {
    margin-bottom: ${gutters.md}px;
  }
`;

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: TemplateAssetInput;
  onSubmit: (values: TemplateAssetInput) => any;
  disabled?: boolean;
  submitTitle?: string;
  materials: Material[];
}

export function TemplateAssetForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
  materials,
}: Props) {
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={templateAssetInputFormikSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isValid, dirty }: FormikProps<TemplateAssetInput>) => (
          <Form>
            <SelectField name={"type"} label={"Тип"}>
              {Object.keys(TemplateAssetType).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </SelectField>
            <TextField
              label={"Название"}
              controlProps={{ size: 50 }}
              disabled={disabled}
              name={"name"}
            />
            <CheckboxField label={"Является массивом"} name={"isArray"} />
            <SelectField name={"materialId"} label={"Материал"}>
              <option value={""}>Не выбрано</option>
              {materials.map((material) => (
                <option key={material.id} value={material.id}>
                  {material.title}
                </option>
              ))}
            </SelectField>
            <Controls>
              <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
                {submitTitle || "Сохранить"}
              </Button>
            </Controls>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
