import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { z } from "shared";
import { TextField, Button } from "client-lib/ui";
import { toFormikSchema } from "client-lib/services";

const Container = styled.div``;

const loginFormSchema = z.object({
  email: z.string().email("Некорректный формат email"),
  password: z.string().min(4, "Пароль должен содержать не меньше 6 символов"),
});

export type LoginFormData = z.infer<typeof loginFormSchema>;
const formikSchema = toFormikSchema(loginFormSchema);

export function LoginForm({
  onSubmit,
  pending,
}: {
  onSubmit: (data: LoginFormData) => void;
  pending?: boolean;
}) {
  const handleSubmit = useCallback(
    (values: LoginFormData) => {
      if (pending) {
        return;
      }
      onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Container>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={formikSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextField
            label="Номер телефона или email:"
            name={"email"}
            controlProps={{ size: 12 }}
            disabled={pending}
          />
          <TextField
            label="Пароль:"
            name={"password"}
            type={"password"}
            controlProps={{ size: 12 }}
            disabled={pending}
          />
          <Button type={"submit"}>Войти</Button>
        </Form>
      </Formik>
    </Container>
  );
}
