import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { errorRed } from "client-lib/styles";
import { LoginForm, LoginFormData } from "./login-form";
import { useAuth } from "../auth-context";
import { SignIn, SignInData, SignInVariables } from "./queries";

const Container = styled.div``;
const AdminMessage = styled.p`
  color: ${errorRed};
`;

export function LoginBlock({
  requireAdmin,
  redirectTo,
}: {
  requireAdmin?: boolean;
  redirectTo?: string | null;
}) {
  const [isAdminMessage, setIsAdminMessage] = useState<boolean>(false);
  const history = useHistory();
  const { setToken, user } = useAuth();
  const [initialUser] = useState(user);

  const [login, { loading }] = useMutation<SignInData, SignInVariables>(
    SignIn,
    {
      onError: () => {},
      onCompleted: (result: SignInData) => {
        setToken(result.SignIn);
      },
    }
  );

  useEffect(
    function onUserChange() {
      if (user === initialUser || !user) {
        return;
      }
      if (requireAdmin && !user.isAdmin) {
        setIsAdminMessage(true);
        return;
      }
      if (redirectTo) {
        history.push(redirectTo);
      }
    },
    [user, initialUser, requireAdmin, history, setIsAdminMessage]
  );

  const handleSubmit = useCallback(
    (values: LoginFormData) => {
      setIsAdminMessage(false);
      login({
        variables: values,
      });
    },
    [login]
  );

  return (
    <Container>
      <LoginForm pending={loading} onSubmit={handleSubmit} />
      {isAdminMessage && (
        <AdminMessage>Требуются права администратора</AdminMessage>
      )}
    </Container>
  );
}
