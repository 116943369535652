import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import { TextureInput, Texture } from "shared";
import { ParseError } from "src/lib";
import { TextureForm } from "./texture-form";
import {
  CreateTexture,
  CreateTextureVars,
  CreateTextureData,
} from "../queries";

interface Props {
  onCompleted: (texture: Texture) => any;
}

export function NewTexture({ onCompleted }: Props) {
  const [create, { loading, error }] = useMutation<
    CreateTextureData,
    CreateTextureVars
  >(CreateTexture);
  const handleSubmit = useCallback(
    async (input: TextureInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateTexture);
    },
    [create, onCompleted]
  );

  return (
    <>
      {error ? <ParseError error={error} /> : null}
      <TextureForm
        initialValues={{
          id: v4(),
          title: "",
          path: "",
          mmWidth: 100,
          mmHeight: 100,
          metalness: null,
          roughness: null,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Создать"}
      />
    </>
  );
}
