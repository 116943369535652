import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { v4 } from "uuid";
import { MaterialInput, Material, MaterialType, UnitType } from "shared";
import { ParseError } from "src/lib";
import { MaterialForm } from "./material-form";
import {
  CreateMaterial,
  CreateMaterialVars,
  CreateMaterialData,
} from "../queries";
import {
  AdminTexturesData,
  AdminTexturesVars,
  AdminTextures,
} from "../../texture/queries";

interface Props {
  onCompleted: (material: Material) => any;
}

export function NewMaterial({ onCompleted }: Props) {
  const { data } = useQuery<AdminTexturesData, AdminTexturesVars>(
    AdminTextures
  );
  const [create, { loading, error }] = useMutation<
    CreateMaterialData,
    CreateMaterialVars
  >(CreateMaterial);
  const handleSubmit = useCallback(
    async (input: MaterialInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateMaterial);
    },
    [create, onCompleted]
  );

  return (
    <>
      {error ? <ParseError error={error} /> : null}
      <MaterialForm
        initialValues={{
          id: v4(),
          title: "",
          pickerTitle: null,
          type: MaterialType.STRUCTURE,
          basicUnit: UnitType.PIECE,
          unitMassKg: 0,
          mmDimension1: null,
          mmDimension2: null,
          mmDimension3: null,
          customFlags: null,
          texture1Id: null,
          texture2Id: null,
          texture3Id: null,
        }}
        textures={data?.AdminTextures ?? []}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Создать"}
      />
    </>
  );
}
