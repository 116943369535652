import gql from "graphql-tag";
import { textureSchema, Texture, TextureInput, TexturePatch } from "shared";
import { getFieldsFromSchema } from "client-lib/services";

export const TEXTURE_FULL_FRAGMENT = gql`
  fragment TextureFullFragment on Texture {
    ${getFieldsFromSchema(textureSchema)}
  }
`;

export const AdminTextures = gql`
  query AdminTextures {
    AdminTextures {
      ...TextureFullFragment
    }
  }
  ${TEXTURE_FULL_FRAGMENT}
`;

export interface AdminTexturesVars {}

export type AdminTexturesData = {
  AdminTextures: Texture[];
};

export const AdminTextureQuery = gql`
  query AdminTexture($textureId: String!) {
    AdminTexture(textureId: $textureId) {
      ...TextureFullFragment
    }
  }
  ${TEXTURE_FULL_FRAGMENT}
`;

export interface AdminTextureVars {
  textureId: string;
}

export type AdminTextureData = {
  AdminTexture: Texture;
};

export const CreateTexture = gql`
  mutation CreateTexture($input: TextureInput!) {
    CreateTexture(input: $input) {
      ...TextureFullFragment
    }
  }
  ${TEXTURE_FULL_FRAGMENT}
`;

export interface CreateTextureVars {
  input: TextureInput;
}

export type CreateTextureData = {
  CreateTexture: Texture;
};

export const UpdateTexture = gql`
  mutation UpdateTexture($textureId: String!, $patch: TexturePatch!) {
    UpdateTexture(textureId: $textureId, patch: $patch) {
      ...TextureFullFragment
    }
  }
  ${TEXTURE_FULL_FRAGMENT}
`;

export interface UpdateTextureVars {
  textureId: string;
  patch: TexturePatch;
}

export type UpdateTextureData = {
  UpdateTexture: Texture;
};
