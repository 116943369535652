import React, { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Template } from "shared";
import { Spinner } from "client-lib/ui";
import { Layout } from "src/features/layout";
import { Templates, TemplatesData, TemplatesVars } from "./queries";
import { NewTemplate } from "./template-form";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
} from "src/lib";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Шаблоны</h1>
      <br />
      <p>
        <b>
          <Link to={"/templates/presets"}>ПЕРЕЙТИ К ПРЕСЕТАМ</Link>
          {"\u2192"}
        </b>
      </p>
      <br />
      {children}
    </Layout>
  );
}

export function TemplatesPage() {
  const { data, loading, error, refetch } = useQuery<
    TemplatesData,
    TemplatesVars
  >(Templates);

  const maxOrderingValue = useMemo<number>(() => {
    if (!data || data.Templates.length === 0) {
      return 0;
    }
    return data.Templates.reduce(
      (max: number, template: Template) => Math.max(max, template.ordering),
      0
    );
  }, [data]);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columns={3}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Тип</HeaderCell>
          <HeaderCell>Название</HeaderCell>
        </HeaderRow>
        {data.Templates.map((template: Template) => {
          const url = `/templates/${template.id}`;
          return (
            <ListRow key={template.id} columns={3}>
              <ListLinkCell to={url}>{template.id}</ListLinkCell>
              <ListLinkCell to={url}>{template.code}</ListLinkCell>
              <ListLinkCell to={url}>{template.title}</ListLinkCell>
            </ListRow>
          );
        })}
      </List>
      <h2>Создать новый шаблон</h2>
      <NewTemplate ordering={maxOrderingValue + 1} onCompleted={refetch} />
    </Wrapper>
  );
}
