import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { User } from "shared";
import { Spinner } from "client-lib/ui";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
  cutId,
} from "src/lib";
import { Layout } from "src/features/layout";
import { Users, UsersData, UsersVars } from "./queries";

const columnTemplate = "1fr 1.2fr 2fr 2fr 0.5fr";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Пользователи</h1>
      {children}
    </Layout>
  );
}

export function UsersPage() {
  const { data, loading, error } = useQuery<UsersData, UsersVars>(Users);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Создан</HeaderCell>
          <HeaderCell>Имя</HeaderCell>
          <HeaderCell>Фамилия</HeaderCell>
          <HeaderCell>Админ?</HeaderCell>
        </HeaderRow>
        {data.Users.map((user: User) => {
          const url = `/users/${user.id}`;
          return (
            <ListRow key={user.id} columnTemplate={columnTemplate}>
              <ListLinkCell to={url}>{cutId(user.id)}</ListLinkCell>
              <ListLinkCell to={url}>
                {new Date(user.createdAt).toLocaleString()}
              </ListLinkCell>
              <ListLinkCell to={url}>{user.nameFirst}</ListLinkCell>
              <ListLinkCell to={url}>{user.nameLast}</ListLinkCell>
              <ListLinkCell to={url}>
                {user.isAdmin ? "Да" : "Нет"}
              </ListLinkCell>
            </ListRow>
          );
        })}
      </List>
    </Wrapper>
  );
}
