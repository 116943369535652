import React, { ReactNode, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Category } from "shared";
import { Spinner } from "client-lib/ui";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { Categories, CategoriesData, CategoriesVars } from "./queries";
import { NewCategory } from "./category-form";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Категории</h1>
      {children}
    </Layout>
  );
}

export function CategoriesPage() {
  const { data, loading, error, refetch } = useQuery<
    CategoriesData,
    CategoriesVars
  >(Categories);

  const maxOrderingValue = useMemo<number>(() => {
    if (!data || data.Categories.length === 0) {
      return 0;
    }
    return data.Categories.reduce(
      (max: number, category: Category) => Math.max(max, category.ordering),
      0
    );
  }, [data]);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      {JSON.stringify(data)}
      <h2>Создать новую категорию</h2>
      <NewCategory
        parentId={null}
        ordering={maxOrderingValue + 1}
        onCompleted={refetch}
      />
    </Wrapper>
  );
}
