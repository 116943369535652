import gql from "graphql-tag";
import { categorySchema, Category, CategoryInput } from "shared";
import { getFieldsFromSchema } from "client-lib/services";

export const CATEGORY_FULL_FRAGMENT = gql`
  fragment CategoryFullFragment on Category {
    ${getFieldsFromSchema(categorySchema)}
  }
`;

export const Categories = gql`
  query Categories($parentId: String) {
    Categories(parentId: $parentId) {
      ...CategoryFullFragment
    }
  }
  ${CATEGORY_FULL_FRAGMENT}
`;

export interface CategoriesVars {
  parentId?: string | null;
}

export type CategoriesData = {
  Categories: Category[];
};

export const CreateCategory = gql`
  mutation CreateCategory($input: CategoryInput!) {
    CreateCategory(input: $input) {
      ...CategoryFullFragment
    }
  }
  ${CATEGORY_FULL_FRAGMENT}
`;

export interface CreateCategoryVars {
  input: CategoryInput;
}

export type CreateCategoryData = {
  CreateCategory: Category;
};
