import React, { ReactNode, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Spinner, Breadcrumbs, Button, useBoolean } from "client-lib";
import { Layout } from "src/features/layout";
import {
  TemplatePresetEnvironments,
  TemplatePresetEnvironmentsData,
  TemplatePresetEnvironmentsVars,
} from "./queries";
import { NewPresetModal } from "./preset-form";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
} from "src/lib";
import { TemplatePresetEnvironment } from "client-lib/fragments";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <Breadcrumbs
        items={[{ title: "Шаблоны", url: "/templates" }, { title: "Пресеты" }]}
      />
      <h1>Пресеты</h1>
      {children}
    </Layout>
  );
}

export function PresetsPage() {
  const [isNewModalOpen, { set: openNewModal, reset: closeNewModal }] =
    useBoolean();
  const { data, loading, error, refetch } = useQuery<
    TemplatePresetEnvironmentsData,
    TemplatePresetEnvironmentsVars
  >(TemplatePresetEnvironments);

  const maxOrderingValue = useMemo<number>(() => {
    if (!data || data.TemplatePresetEnvironments.length === 0) {
      return 0;
    }
    return data.TemplatePresetEnvironments.reduce(
      (max: number, presetEnv: TemplatePresetEnvironment) =>
        Math.max(max, presetEnv.templatePreset.ordering),
      0
    );
  }, [data]);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columns={4}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Шаблон</HeaderCell>
          <HeaderCell>Название</HeaderCell>
          <HeaderCell>Опубликован?</HeaderCell>
        </HeaderRow>
        {data.TemplatePresetEnvironments.map(
          (preset: TemplatePresetEnvironment) => {
            const url = `/templates/presets/${preset.id}`;
            return (
              <ListRow key={preset.id} columns={4}>
                <ListLinkCell to={url}>{preset.id}</ListLinkCell>
                <ListLinkCell to={url}>{preset.template.title}</ListLinkCell>
                <ListLinkCell to={url}>
                  {preset.templatePreset.title}
                </ListLinkCell>
                <ListLinkCell to={url}>
                  {preset.templatePreset.isPublic ? "Да" : "Нет"}
                </ListLinkCell>
              </ListRow>
            );
          }
        )}
      </List>
      <br />
      <Button onClick={openNewModal}>Создать новый пресет</Button>
      {isNewModalOpen && (
        <NewPresetModal
          ordering={maxOrderingValue + 1}
          onCloseRequest={closeNewModal}
          onCompleted={() => {
            refetch();
            closeNewModal();
          }}
        />
      )}
    </Wrapper>
  );
}
