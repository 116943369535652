import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { SupportMessage } from "shared";
import { Breadcrumbs, Spinner, FormatDateTime } from "client-lib";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
} from "src/lib";
import { Layout } from "src/features/layout";
import {
  SupportMessages,
  SupportMessagesData,
  SupportMessagesVars,
} from "./queries";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Техподдержка", url: "/support" },
          { title: "Сообщения пользователей" },
        ]}
      />
      <h1>Сообщения пользователей</h1>
      {children}
    </Layout>
  );
}

export function SupportMessagesPage() {
  const { data, loading, error } = useQuery<
    SupportMessagesData,
    SupportMessagesVars
  >(SupportMessages);

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <List>
        <HeaderRow columns={4}>
          <HeaderCell>Получено</HeaderCell>
          <HeaderCell>Имя</HeaderCell>
          <HeaderCell>Контакт</HeaderCell>
          <HeaderCell>Текст</HeaderCell>
        </HeaderRow>
        {data.SupportMessages.map((message: SupportMessage) => {
          const url = `/support/messages/${message.id}`;
          return (
            <ListRow key={message.id} columns={4}>
              <ListLinkCell to={url}>
                <FormatDateTime date={message.createdAt} />
              </ListLinkCell>
              <ListLinkCell to={url}>{message.senderName}</ListLinkCell>
              <ListLinkCell to={url}>{message.senderEmailOrPhone}</ListLinkCell>
              <ListLinkCell to={url}>
                {message.message.slice(0, 50)}
              </ListLinkCell>
            </ListRow>
          );
        })}
      </List>
    </Wrapper>
  );
}
