import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { TexturesPage } from "./textures-page";
import { TexturePage } from "./texture-page";

export function TextureRouter() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={TexturesPage} />
      <Route exact path={`${path}/:textureId`} component={TexturePage} />
    </Switch>
  );
}
