import React, { useState, useEffect, useCallback, useRef } from "react";
import { z } from "shared";
import styled from "@emotion/styled";
import { ITEM_HEIGHT } from "client-lib/styles";
import { StyledInput } from "client-lib/ui";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: ${ITEM_HEIGHT}px;
`;

interface Props<T> {
  value: T | null;
  placeholder?: string;
  schema: z.ZodAny | z.ZodEffects<any>;
  onChange: (v: T | null) => void;
}

export function Editable<T>({
  value: initialValue,
  placeholder,
  schema,
  onChange,
}: Props<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [value, setValue] = useState<T | null>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      const newValue = e.target.value || null;
      setValue(newValue);
      if (newValue === null) {
        onChange(newValue);
        return;
      }
      const parsed = schema.safeParse(newValue);
      if (parsed.success) {
        setIsError(false);
        onChange(parsed.data);
      } else {
        setIsError(true);
      }
    },
    [onChange]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.code) {
        case "Enter":
        case "Escape":
          inputRef.current?.blur();
          break;
      }
    },
    [inputRef]
  );

  if (value === null && !isEditing) {
    return (
      <Container onClick={() => setIsEditing(true)}>
        {placeholder ?? "Введите значение"}
      </Container>
    );
  }

  return (
    <Container>
      <StyledInput
        ref={inputRef}
        seamless
        autoFocus={isEditing}
        error={isError ? "Error" : undefined}
        value={value === null ? "" : String(value)}
        onChange={handleChange}
        onBlur={() => setIsEditing(false)}
        onKeyDown={handleKeyPress}
      />
    </Container>
  );
}

type ImplementationProps<T> = Omit<Props<T>, "schema">;

function nullableStringToInt(val: string | null, ctx: z.RefinementCtx) {
  if (val === null) {
    return val;
  }
  const number = Number(val);
  const parsed = parseInt(val);
  if (isNaN(parsed) || number !== parsed) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Not a number",
    });

    // This is a special symbol you can use to
    // return early from the transform function.
    // It has type `never` so it does not affect the
    // inferred return type.
    return z.NEVER;
  }
  return parsed;
}

const nullableIntInputSchema = z
  .string()
  .nullable()
  .transform(nullableStringToInt);

export function NullableIntEditable(props: ImplementationProps<number | null>) {
  return <Editable schema={nullableIntInputSchema} {...props} />;
}
