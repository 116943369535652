import React, { useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { omit } from "lodash";
import {
  PricingCost,
  PricingCostInput,
  PricingLimit,
  TechProcessCode,
} from "shared";
import { ParseError } from "src/lib";
import { PricingCostsForm } from "./pricing-costs-form";
import {
  SavePricingCosts,
  SavePricingCostsVars,
  SavePricingCostsData,
} from "../queries";
import { pricingCostInputsSchema } from "./types";

interface Props {
  materialId: string | null;
  techProcessCode: TechProcessCode | null;
  pricingCosts: PricingCost[];
  onCompleted: () => void;
}

export function PricingCostsEditor({
  materialId,
  techProcessCode,
  pricingCosts,
  onCompleted,
}: Props) {
  const initialValues = useMemo<PricingCostInput[]>(() => {
    const limitKeys = Object.keys(PricingLimit) as PricingLimit[];
    const hash: Partial<Record<PricingLimit, PricingCost>> = {};
    for (const pricingCost of pricingCosts) {
      hash[pricingCost.limit] = pricingCost;
    }
    const result: PricingCostInput[] = [];
    for (const limit of limitKeys) {
      if (hash[limit]) {
        result.push(
          omit(
            hash[limit] as PricingCost,
            "id",
            "createdAt",
            "updatedAt",
            "deletedAt"
          )
        );
      } else {
        result.push({
          materialId,
          techProcessCode,
          limit,
          costKopecks: null,
        });
      }
    }
    return result;
  }, [pricingCosts]);

  const [saveCosts, { loading, error }] = useMutation<
    SavePricingCostsData,
    SavePricingCostsVars
  >(SavePricingCosts);

  const handleSubmit = useCallback(
    async (costInputs: PricingCostInput[]) => {
      await saveCosts({
        variables: {
          costInputs: pricingCostInputsSchema.parse(costInputs),
        },
      });
      onCompleted();
    },
    [saveCosts, onCompleted]
  );

  return (
    <>
      {error ? <ParseError error={error} /> : null}
      <PricingCostsForm
        initialValues={initialValues}
        disabled={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
}
