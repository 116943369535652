import React, { useCallback, useMemo } from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { useField } from "formik";
import { TemplatePresetImageInput, Image } from "shared";
import { ImagePickerModal } from "src/features/image";
import {
  borderGrey,
  gutters,
  grey20,
  useBoolean,
  borderRadius,
  Button,
  TextField,
} from "client-lib";
import { PresetImages } from "./preset-images";

const Container = styled.div`
  margin-top: ${gutters.md}px;
  border: 1px solid ${borderGrey};
  background-color: ${grey20};
  border-radius: ${borderRadius.md}px;
  padding: ${gutters.md}px;
`;
const Controls = styled.div``;

interface Props {
  name: string;
}

export function PresetImagesField({ name }: Props) {
  const [isPickerModalOpen, { set: openPickerModal, reset: closePickerModal }] =
    useBoolean();
  const [{ value: presetImages }, , { setValue }] =
    useField<TemplatePresetImageInput[]>(name);

  const maxOrdering = useMemo<number>(() => {
    return presetImages.reduce(
      (acc, presetImage) => Math.max(acc, presetImage.ordering),
      0
    );
  }, [presetImages]);

  const handlePick = useCallback(
    function (image: Image) {
      const newValue = [
        ...presetImages,
        {
          id: v4(),
          ordering: maxOrdering + 1,
          imageId: image.id,
          title: null,
        },
      ];
      setValue(newValue);
    },
    [presetImages, maxOrdering]
  );

  const handleRemove = useCallback(
    function (index: number) {
      if (!window.confirm("Точно хотите удалить?")) {
        return;
      }
      const newValue = [
        ...presetImages.slice(0, index),
        ...presetImages.slice(index + 1),
      ];
      setValue(newValue);
    },
    [presetImages, maxOrdering]
  );

  const controls = useCallback(
    (_: TemplatePresetImageInput, index: number) => {
      return <TextField name={`${name}[${index}].title`} />;
    },
    [name]
  );

  return (
    <Container>
      <Controls>
        <Button onClick={openPickerModal} size={"XS"}>
          Добавить
        </Button>
      </Controls>
      <PresetImages
        presetImages={presetImages}
        onRemove={handleRemove}
        emptyMessage={"Добавьте первое изображение"}
        controls={controls}
      />
      {isPickerModalOpen && (
        <ImagePickerModal
          onCloseRequest={closePickerModal}
          onPick={handlePick}
        />
      )}
    </Container>
  );
}
