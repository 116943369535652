import React, { useCallback } from "react";
import { Image } from "shared";
import { useQuery } from "@apollo/client";
import { Spinner } from "@vkontakte/vkui";
import { ParseError } from "src/lib";
import { Modal } from "client-lib/ui";
import { Images, ImagesData, ImagesVars } from "./queries";
import { ImageList } from "./image-list";

interface Props {
  onPick: (image: Image) => void;
  onCloseRequest: () => any;
}

export function ImagePickerModal({ onPick, onCloseRequest }: Props) {
  const { data, loading, error } = useQuery<ImagesData, ImagesVars>(Images);

  const handleClick = useCallback(
    (image: Image) => {
      onPick(image);
      onCloseRequest();
    },
    [onPick, onCloseRequest]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title={"Выберите изображение"}>
      {error ? <ParseError error={error} /> : null}
      {loading ? <Spinner /> : null}
      {data && <ImageList images={data.Images} onClick={handleClick} />}
    </Modal>
  );
}
