import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import { History, Location } from "history";
import { constructLoginUrl } from "src/features/auth/service";

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

const DateWrapper = (v: string | null) => (v ? new Date(v) : v);
const dateFieldPolicy = { read: DateWrapper };

export function createClient(history: History, location: Location) {
  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Contract: {
          fields: {
            createdAt: dateFieldPolicy,
            offerExpectedAt: dateFieldPolicy,
            offerActualTill: dateFieldPolicy,
            paidAt: dateFieldPolicy,
          },
        },
        SupportMessage: {
          fields: {
            createdAt: dateFieldPolicy,
          },
        },
      },
    }),
    link: ApolloLink.from([
      setContext((_, { headers }) => {
        const token = localStorage.getItem("auth-token");
        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
          },
        };
      }),
      onError(({ graphQLErrors }: ErrorResponse) => {
        if (graphQLErrors && graphQLErrors.length) {
          if (
            graphQLErrors.some(
              (error: GraphQLError) =>
                error.extensions && error.extensions.code === "UNAUTHENTICATED"
            )
          ) {
            // this will also reset session due to client.onClearStore hook
            client.clearStore();
            history.push(constructLoginUrl(location));
            return;
          }
        }
      }),
      createHttpLink({
        uri: process.env.REACT_APP_API_URL,
      }),
    ]),
  });
  return client;
}
