import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";
import { Template } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
} from "client-lib/ui";
import { useBoolean } from "client-lib/services";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  TemplateEnvironmentQuery,
  TemplateEnvironmentData,
  TemplateEnvironmentVars,
} from "./queries";
import { UpdateTemplateAssetModal } from "./template-asset-form";

function Wrapper({
  children,
  template,
  templateId,
  assetId,
}: {
  children?: ReactNode;
  template?: Template;
  templateId: string;
  assetId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Шаблоны", url: "/templates" },
          {
            title: template ? template.title : `${templateId}`,
            url: `/templates/${templateId}`,
          },
          { title: `Параметр ${assetId}` },
        ]}
      />
      <h1>
        Шаблон {template ? `\u00ab${template.title}\u00bb` : `${templateId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function TemplateAssetPage() {
  const [
    isUpdateAssetOpen,
    { setTrue: openUpdateAsset, setFalse: closeUpdateAsset },
  ] = useBoolean(false);
  const { params } = useRouteMatch<{ templateId: string; assetId: string }>();
  const { data, loading, error } = useQuery<
    TemplateEnvironmentData,
    TemplateEnvironmentVars
  >(TemplateEnvironmentQuery, {
    variables: {
      templateId: params.templateId,
    },
  });

  if (loading) {
    return (
      <Wrapper templateId={params.templateId} assetId={params.assetId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper templateId={params.templateId} assetId={params.assetId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper templateId={params.templateId} assetId={params.assetId} />;
  }

  const template = data.TemplateEnvironment.template;
  const asset = data.TemplateEnvironment.assets.find(
    (a) => a.id === params.assetId
  );

  if (!asset) {
    return <Wrapper templateId={params.templateId} assetId={params.assetId} />;
  }

  return (
    <Wrapper
      templateId={params.templateId}
      template={template}
      assetId={params.assetId}
    >
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Тип</TH>
            <TD>{asset.type}</TD>
          </TR>
          <TR>
            <TH>Название</TH>
            <TD>{asset.name}</TD>
          </TR>
          <TR>
            <TH>Массив?</TH>
            <TD>{asset.isArray ? "Да" : "Нет"}</TD>
          </TR>
          <TR>
            <TH>Материал</TH>
            <TD>
              <Link to={`/materials/${asset.materialId}`}>
                {asset.materialId}
              </Link>
            </TD>
          </TR>
        </TBody>
      </Table>
      <br />
      <Button onClick={openUpdateAsset}>Редактировать</Button>
      {isUpdateAssetOpen && (
        <UpdateTemplateAssetModal
          templateAsset={asset}
          onCloseRequest={closeUpdateAsset}
        />
      )}
    </Wrapper>
  );
}
