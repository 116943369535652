import React from "react";
import styled from "@emotion/styled";
import { TemplatePresetInput, Template } from "shared";
import {
  Button,
  TextField,
  IntField,
  MultilineTextField,
  SelectField,
  CheckboxField,
} from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { templatePresetInputFormikSchema } from "./types";
import { PresetImagesField } from "../preset-images";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: TemplatePresetInput;
  onSubmit: (values: TemplatePresetInput) => any;
  disabled?: boolean;
  submitTitle?: string;
  lockTemplate?: boolean;
  templates?: Template[];
}

export function PresetForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
  lockTemplate = false,
  templates = [],
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={templatePresetInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<TemplatePresetInput>) => (
        <Form>
          <TextField
            label={"Название"}
            controlProps={{ size: 50 }}
            disabled={disabled}
            name={"title"}
          />
          <MultilineTextField
            label={"Описание"}
            disabled={disabled}
            name={"description"}
          />
          <IntField
            label={"Номер для сортировки"}
            disabled={disabled}
            name={"ordering"}
          />
          <br />
          <CheckboxField name={"isPublic"} label={"Опубликован"} />
          <br />
          <SelectField name={"templateId"} disabled={lockTemplate}>
            <option value={""}>Не выбрано</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.title}
              </option>
            ))}
          </SelectField>
          <PresetImagesField name={"images"} />
          <MultilineTextField
            label={"Переменные"}
            disabled={disabled}
            name={"templateVariables"}
          />
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Сохранить"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
