import React from "react";
import styled from "@emotion/styled";
import { formatPrice } from "shared";
import { StyledInput, withField } from "client-lib";

const Container = styled.div`
  display: flex;
  align-items: center;
  input {
    text-align: right;
  }
`;

const Field = withField(StyledInput, {
  translateInputValue: (rawInputValue: string) => {
    if (rawInputValue === "") {
      return rawInputValue;
    }

    // the inputs ending up with a period (like "1.") are treated by correct numbers by JS
    // but we should not transform them to numbers, because it will throw out the period
    // which makes typing period at the end impossible for users
    if (
      typeof rawInputValue === "string" &&
      rawInputValue[rawInputValue.length - 1] === "."
    ) {
      return rawInputValue;
    }

    const numericValue = Math.floor(Number(rawInputValue) * 100);
    if (Number.isNaN(numericValue)) {
      return rawInputValue;
    }
    return numericValue;
  },
  formatValue: (kopecks: number) => {
    return formatPrice(kopecks, { split: false, separator: "." });
  },
});

interface Props {
  name: string;
  className?: string;
}

export function KopecksField({ name, className }: Props) {
  return (
    <Container className={className}>
      <Field name={name} /> руб.
    </Container>
  );
}
