import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { MaterialsPage } from "./materials-page";
import { MaterialPage } from "./material-page";

export function MaterialRouter() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={MaterialsPage} />
      <Route exact path={`${path}/:materialId`} component={MaterialPage} />
    </Switch>
  );
}
