import React, { useCallback } from "react";
import { pick } from "lodash";
import { useMutation } from "@apollo/client";
import { TemplateInput, Template } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib/ui";
import { TemplateForm } from "./template-form";
import {
  UpdateTemplate,
  UpdateTemplateVars,
  UpdateTemplateData,
} from "../queries";

interface Props {
  template: Template;
  onCloseRequest: () => any;
}

export function UpdateTemplateModal({ template, onCloseRequest }: Props) {
  const [update, { loading, error }] = useMutation<
    UpdateTemplateData,
    UpdateTemplateVars
  >(UpdateTemplate);
  const handleSubmit = useCallback(
    async (input: TemplateInput) => {
      await update({
        variables: {
          templateId: template.id,
          patch: pick(input, "title", "image", "ordering"),
        },
      });
      onCloseRequest();
    },
    [update, onCloseRequest]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title={"Редактировать шаблон"}>
      {error ? <ParseError error={error} /> : null}
      <TemplateForm
        initialValues={{
          ...template,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Сохранить"}
      />
    </Modal>
  );
}
