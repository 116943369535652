import React from "react";
import styled from "@emotion/styled";
import { PricingCostInput, PricingLimitValues } from "shared";
import { Button } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { pricingCostInputFormikSchema } from "./types";
import {
  HeaderCell,
  HeaderRow,
  List,
  ListCell,
  ListRow,
  KopecksField,
} from "src/lib";

const columnTemplate = "1fr 1fr";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: PricingCostInput[];
  onSubmit: (values: PricingCostInput[]) => any;
  disabled?: boolean;
}

export function PricingCostsForm({ initialValues, onSubmit, disabled }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={pricingCostInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, values }: FormikProps<PricingCostInput[]>) => (
        <Form>
          <List>
            <HeaderRow columnTemplate={columnTemplate}>
              <HeaderCell>Лимит (от скольки шт)</HeaderCell>
              <HeaderCell>Цена за единицу (копеек)</HeaderCell>
            </HeaderRow>
            {values.map((costInput: PricingCostInput, index: number) => {
              return (
                <ListRow key={index} columnTemplate={columnTemplate}>
                  <ListCell>{PricingLimitValues[costInput.limit]}</ListCell>
                  <ListCell>
                    <KopecksField name={`[${index}].costKopecks`} />
                  </ListCell>
                </ListRow>
              );
            })}
          </List>
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {"Сохранить цены"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
