import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, Link } from "react-router-dom";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  FormatDateTime,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  SupportMessageQuery,
  SupportMessageData,
  SupportMessageVars,
} from "./queries";

function Wrapper({
  children,
  supportMessageId,
}: {
  children?: ReactNode;
  supportMessageId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Техподдержка", url: "/support" },
          { title: "Сообщения пользователей", url: "/support/messages" },
          { title: supportMessageId },
        ]}
      />
      <h1>Сообщение #{supportMessageId}</h1>
      {children}
    </Layout>
  );
}

export function SupportMessagePage() {
  const { params } = useRouteMatch<{ supportMessageId: string }>();
  const { data, loading, error } = useQuery<
    SupportMessageData,
    SupportMessageVars
  >(SupportMessageQuery, {
    variables: {
      supportMessageId: params.supportMessageId,
    },
  });

  if (loading) {
    return (
      <Wrapper supportMessageId={params.supportMessageId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper supportMessageId={params.supportMessageId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper supportMessageId={params.supportMessageId} />;
  }

  const supportMessage = data.SupportMessage;
  return (
    <Wrapper supportMessageId={params.supportMessageId}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>Получено</TH>
            <TD>
              <FormatDateTime date={supportMessage.createdAt} />
            </TD>
          </TR>
          <TR>
            <TH>Имя отправителя</TH>
            <TD>{supportMessage.senderName}</TD>
          </TR>
          <TR>
            <TH>Контакт отправителя</TH>
            <TD>{supportMessage.senderEmailOrPhone}</TD>
          </TR>
          <TR>
            <TH>Существующий пользователь?</TH>
            <TD>
              {supportMessage.userId ? (
                <Link to={`/users/${supportMessage.userId}`}>
                  {supportMessage.userId}
                </Link>
              ) : (
                "Нет"
              )}
            </TD>
          </TR>
          <TR>
            <TH>Сообщение</TH>
            <TD>{supportMessage.message}</TD>
          </TR>
        </TBody>
      </Table>
    </Wrapper>
  );
}
