import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { v4 } from "uuid";
import { TemplateAssetInput, TemplateAsset, TemplateAssetType } from "shared";
import { ParseError } from "src/lib";
import { TemplateAssetForm } from "./template-asset-form";
import {
  CreateTemplateAsset,
  CreateTemplateAssetVars,
  CreateTemplateAssetData,
} from "../queries";
import {
  AdminMaterials,
  AdminMaterialsData,
  AdminMaterialsVars,
} from "src/features/material/queries";

interface Props {
  ordering: number;
  templateId: string;
  onCompleted: (templateAsset: TemplateAsset) => any;
}

export function NewTemplateAsset({ ordering, templateId, onCompleted }: Props) {
  const { data } = useQuery<AdminMaterialsData, AdminMaterialsVars>(
    AdminMaterials
  );
  const [create, { loading, error }] = useMutation<
    CreateTemplateAssetData,
    CreateTemplateAssetVars
  >(CreateTemplateAsset);
  const handleSubmit = useCallback(
    async (input: TemplateAssetInput) => {
      const { data } = await create({ variables: { input } });
      onCompleted(data!.CreateTemplateAsset);
    },
    [create, onCompleted]
  );

  return (
    <>
      {error ? <ParseError error={error} /> : null}
      <TemplateAssetForm
        initialValues={{
          id: v4(),
          ordering,
          templateId,
          type: TemplateAssetType.MATERIAL,
          name: "",
          isArray: false,
          materialId: null,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Создать"}
        materials={data?.AdminMaterials ?? []}
      />
    </>
  );
}
