import { Location } from "history";

export function constructLoginUrl(location: Location): string {
  const { pathname, search } = location;
  const redirectTo = `${pathname}${search}`;

  return redirectTo && redirectTo !== "/"
    ? `/login?redirect=${encodeURIComponent(redirectTo)}`
    : `/login`;
}
