import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Spinner } from "@vkontakte/vkui";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { Images, ImagesData, ImagesVars } from "./queries";
import { NewImage } from "./image-form";
import { ImageList } from "./image-list";

function Wrapper({ children }: { children?: ReactNode }) {
  return (
    <Layout>
      <h1>Изображения</h1>
      {children}
    </Layout>
  );
}

export function ImagesPage() {
  const { data, loading, error, refetch } = useQuery<ImagesData, ImagesVars>(
    Images
  );

  if (loading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <ImageList images={data.Images} />
      <h2>Создать новое изображение</h2>
      <NewImage onCompleted={refetch} />
    </Wrapper>
  );
}
