import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { TemplatesPage } from "./templates-page";
import { PresetRouter } from "./presets";
import { TemplatePage } from "./template-page";
import { TemplateAssetPage } from "./template-asset-page";

export function TemplateRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/presets`} component={PresetRouter} />
      <Route exact path={path} component={TemplatesPage} />
      <Route exact path={`${path}/:templateId`} component={TemplatePage} />
      <Route
        exact
        path={`${path}/:templateId/:assetId`}
        component={TemplateAssetPage}
      />
    </Switch>
  );
}
