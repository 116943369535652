import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ImagesPage } from "./images-page";

export function ImageRouter() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ImagesPage} />
    </Switch>
  );
}
