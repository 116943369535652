import gql from "graphql-tag";
import { PricingCostInput } from "shared";

export const SavePricingCosts = gql`
  mutation SavePricingCosts($costInputs: [PricingCostInput]!) {
    SavePricingCosts(costInputs: $costInputs)
  }
`;

export interface SavePricingCostsVars {
  costInputs: PricingCostInput[];
}

export type SavePricingCostsData = {
  SavePricingCosts: {};
};
