import gql from "graphql-tag";
import { SupportMessage } from "shared";
import { SUPPORT_MESSAGE_FULL_FRAGMENT } from "client-lib";

export const SupportMessages = gql`
  query SupportMessages {
    SupportMessages {
      ...SupportMessageFullFragment
    }
  }
  ${SUPPORT_MESSAGE_FULL_FRAGMENT}
`;

export interface SupportMessagesVars {}

export type SupportMessagesData = {
  SupportMessages: SupportMessage[];
};

export const SupportMessageQuery = gql`
  query SupportMessage($supportMessageId: String!) {
    SupportMessage(supportMessageId: $supportMessageId) {
      ...SupportMessageFullFragment
    }
  }
  ${SUPPORT_MESSAGE_FULL_FRAGMENT}
`;

export interface SupportMessageVars {
  supportMessageId: string;
}

export type SupportMessageData = {
  SupportMessage: SupportMessage;
};
