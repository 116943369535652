import gql from "graphql-tag";
import { Order, User } from "shared";
import { USER_FULL_FRAGMENT, ORDER_FULL_FRAGMENT } from "client-lib/fragments";

export const Users = gql`
  query Users {
    Users {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;

export interface UsersVars {
  parentId?: string | null;
}

export type UsersData = {
  Users: User[];
};

export const UserEnvironmentQuery = gql`
  query UserEnvironment($userId: String!) {
    UserEnvironment(userId: $userId) {
      user {
        ...UserFullFragment
      }
      orders {
        ...OrderFullFragment
      }
    }
  }
  ${USER_FULL_FRAGMENT}
  ${ORDER_FULL_FRAGMENT}
`;

export interface UserEnvironmentVars {
  userId: string;
}

export type UserEnvironmentData = {
  UserEnvironment: {
    user: User;
    orders: Order[];
  };
};

export const UserToken = gql`
  query UserToken($userId: String!) {
    UserToken(userId: $userId)
  }
`;

export interface UserTokenVars {
  userId: string;
}

export type UserTokenData = {
  UserToken: string;
};
