import React from "react";
import { Layout } from "src/features/layout";

export function DashboardPage() {
  return (
    <Layout>
      <h1>Админка</h1>
    </Layout>
  );
}
