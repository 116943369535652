import React, { useMemo } from "react";
import { LoginBlock } from "./login-block";
import { useLocation } from "react-router-dom";

export function LoginPage() {
  const { search } = useLocation();
  const redirectTo = useMemo<string | null>(() => {
    const redirectValue = new URLSearchParams(search).get("redirect");
    return redirectValue ? decodeURIComponent(redirectValue) : null;
  }, [search]);

  return (
    <>
      <h1>Войти в Метафабрику</h1>
      <LoginBlock redirectTo={redirectTo || "/"} />
    </>
  );
}
