import React from "react";
import styled from "@emotion/styled";
import { ImageInput } from "shared";
import { v4 } from "uuid";
import { Button, TextField, IntField } from "client-lib/ui";
import { Form, Formik, FormikProps } from "formik";
import { gutters, grey30 } from "client-lib/styles";
import { imageInputFormikSchema } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

interface Props {
  initialValues: ImageInput;
  onSubmit: (values: ImageInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function ImageForm({
  initialValues,
  onSubmit,
  disabled,
  submitTitle,
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={imageInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty, values, setValues }: FormikProps<ImageInput>) => (
        <Form>
          <Button
            size={"S"}
            onClick={() => {
              setValues({ ...values, id: v4() });
            }}
          >
            generate id
          </Button>
          <TextField
            label={"ID"}
            controlProps={{ size: 24 }}
            disabled={disabled}
            name={"id"}
          />
          <TextField
            label={"mime type"}
            controlProps={{ size: 15 }}
            disabled={disabled}
            name={"mimeType"}
          />
          <IntField
            label={"Ширина"}
            controlProps={{ size: 15 }}
            disabled={disabled}
            name={"originalWidth"}
          />
          <IntField
            label={"Высота"}
            controlProps={{ size: 15 }}
            disabled={disabled}
            name={"originalHeight"}
          />
          <Controls>
            <Button type={"submit"} disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Сохранить"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
