import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { PresetsPage } from "./presets-page";
import { PresetPage } from "./preset-page";

export function PresetRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={PresetsPage} />
      <Route exact path={`${path}/:presetId`} component={PresetPage} />
    </Switch>
  );
}
