import React from "react";
import styled from "@emotion/styled";
import { errorRed } from "client-lib/styles";

const Root = styled.div`
  color: ${errorRed};
`;

export function Error({ children }: { children: any }) {
  return <Root>{children || "Ошибка загрузки"}</Root>;
}
