import { pick } from "lodash";
import React, { ReactNode, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import { Texture, TextureInput } from "shared";
import { Breadcrumbs, Spinner } from "client-lib/ui";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import {
  AdminTextureQuery,
  AdminTextureData,
  AdminTextureVars,
} from "./queries";
import {
  UpdateTexture,
  UpdateTextureData,
  UpdateTextureVars,
} from "src/features/texture/queries";
import { TextureForm } from "./texture-form";

function Wrapper({
  children,
  texture,
  textureId,
}: {
  children?: ReactNode;
  texture?: Texture;
  textureId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Текстуры", url: "/textures" },
          { title: texture ? texture.title : `${textureId}` },
        ]}
      />
      <h1>
        Текстура {texture ? `\u00ab${texture.title}\u00bb` : `${textureId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function TexturePage() {
  const { params } = useRouteMatch<{ textureId: string }>();
  const { data, loading, error } = useQuery<AdminTextureData, AdminTextureVars>(
    AdminTextureQuery,
    {
      variables: {
        textureId: params.textureId,
      },
    }
  );

  const [update, updateMeta] = useMutation<
    UpdateTextureData,
    UpdateTextureVars
  >(UpdateTexture);

  const handleSubmit = useCallback(
    async (input: TextureInput) => {
      await update({
        variables: {
          textureId: params.textureId,
          patch: pick(
            input,
            "title",
            "path",
            "mmWidth",
            "mmHeight",
            "metalness",
            "roughness"
          ),
        },
      });
    },
    [update]
  );

  if (loading) {
    return (
      <Wrapper textureId={params.textureId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper textureId={params.textureId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper textureId={params.textureId} />;
  }

  const texture = data.AdminTexture;
  return (
    <Wrapper textureId={params.textureId} texture={texture}>
      {updateMeta.error ? <ParseError error={updateMeta.error} /> : null}
      <TextureForm
        initialValues={{
          ...texture,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Сохранить"}
      />
    </Wrapper>
  );
}
