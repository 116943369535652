import React, { useCallback } from "react";
import { omit } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { TemplateAssetInput, TemplateAsset } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib/ui";
import { TemplateAssetForm } from "./template-asset-form";
import {
  UpdateTemplateAsset,
  UpdateTemplateAssetVars,
  UpdateTemplateAssetData,
} from "../queries";
import {
  AdminMaterials,
  AdminMaterialsData,
  AdminMaterialsVars,
} from "src/features/material/queries";

interface Props {
  templateAsset: TemplateAsset;
  onCloseRequest: () => any;
}

export function UpdateTemplateAssetModal({
  templateAsset,
  onCloseRequest,
}: Props) {
  const { data } = useQuery<AdminMaterialsData, AdminMaterialsVars>(
    AdminMaterials
  );
  const [update, { loading, error }] = useMutation<
    UpdateTemplateAssetData,
    UpdateTemplateAssetVars
  >(UpdateTemplateAsset);
  const handleSubmit = useCallback(
    async (input: TemplateAssetInput) => {
      await update({
        variables: {
          templateAssetId: templateAsset.id,
          patch: omit(
            input,
            "id",
            "templateId",
            "__typename",
            "createdAt",
            "updatedAt",
            "deletedAt",
            "materialExpanded"
          ),
        },
      });
      onCloseRequest();
    },
    [update, onCloseRequest]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title={"Редактировать ассет"}>
      {error ? <ParseError error={error} /> : null}
      <TemplateAssetForm
        initialValues={{
          ...templateAsset,
        }}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle={"Сохранить"}
        materials={data?.AdminMaterials ?? []}
      />
    </Modal>
  );
}
