import gql from "graphql-tag";
import { imageSchema, Image, ImageInput } from "shared";
import { getFieldsFromSchema } from "client-lib/services";

export const IMAGE_FULL_FRAGMENT = gql`
  fragment ImageFullFragment on Image {
    ${getFieldsFromSchema(imageSchema)}
  }
`;

export const Images = gql`
  query Images {
    Images {
      ...ImageFullFragment
    }
  }
  ${IMAGE_FULL_FRAGMENT}
`;

export interface ImagesVars {}

export type ImagesData = {
  Images: Image[];
};

export const CreateImage = gql`
  mutation CreateImage($input: ImageInput!) {
    CreateImage(input: $input) {
      ...ImageFullFragment
    }
  }
  ${IMAGE_FULL_FRAGMENT}
`;

export interface CreateImageVars {
  input: ImageInput;
}

export type CreateImageData = {
  CreateImage: Image;
};
